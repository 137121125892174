/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import IMask from 'imask';

import { CREDIT_CARD, MONTH, PAUSED, PREPAY } from 'consts';
import { fetchPermissions, resetPermissionsState } from './permissionsSlice';
import { buyClicksCampaignApi } from './buyClicksCampaignApi';
import { buyLeadsCampaignApi } from './buyLeadsCampaignApi';

const initialState = {
  me: null,
  buyer: null,
  seller: null,
  creditCards: [],
  buyerDayParting: null,
};

/**
 * Slice
 */
export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetAccountState: () => {
      return { ...initialState };
    },

    setAccountData: (state, action) => {
      state.me = action.payload.me || state.me;
      state.buyer = action.payload.buyer || state.buyer;
      state.seller = action.payload.seller || state.seller;
      state.creditCards = action.payload.creditCards || state.creditCards;
      state.buyerDayParting =
        action.payload.buyerDayParting || state.buyerDayParting;
    },

    updateMe: (state, action) => {
      state.me = { ...state.me, ...action.payload.me };
    },

    updateBuyer: (state, action) => {
      state.buyer = { ...state.buyer, ...action.payload.buyer };
    },

    addCreditCard: (state, action) => {
      state.creditCards = [
        action.payload.creditCard,
        ...state.creditCards.map((cC) => {
          return {
            ...cC,
            isPrimary: false,
          };
        }),
      ];
    },

    makePrimaryCreditCard: (state, action) => {
      state.creditCards = [
        ...state.creditCards.map((cC) => {
          if (action.payload.id === cC.id) {
            return {
              ...cC,
              isPrimary: true,
            };
          }
          return {
            ...cC,
            isPrimary: false,
          };
        }),
      ];
    },

    removeCreditCard: (state, action) => {
      state.creditCards = state.creditCards.filter(
        (cC) => cC.id !== action.payload.id,
      );
    },

    addBuyerDayParting: (state, action) => {
      state.buyerDayParting = [
        ...state.buyerDayParting,
        action.payload.buyerDayParting,
      ];
    },

    updateBuyerDayParting: (state, action) => {
      state.buyerDayParting = state.buyerDayParting.map((day) => {
        if (day.id === action.payload.buyerDayParting.id) {
          return {
            ...day,
            ...action.payload.buyerDayParting,
          };
        }
        return day;
      });
    },

    deleteBuyerDayParting: (state, action) => {
      state.buyerDayParting = state.buyerDayParting.filter(
        (day) => day.id !== action.payload.id,
      );
    },
  },
});

/**
 * Thunks
 */

export const {
  resetAccountState,
  setAccountData,
  updateMe: updateMeAction,
  updateBuyer: updateBuyerAction,
  addCreditCard: addCreditCardAction,
  removeCreditCard: removeCreditCardAction,
  makePrimaryCreditCard: makePrimaryCreditCardAction,
  addBuyerDayParting: addBuyerDayPartingAction,
  updateBuyerDayParting: updateBuyerDayPartingAction,
  deleteBuyerDayParting: deleteBuyerDayPartingAction,
} = accountSlice.actions;

export const fetchMe = () => async (dispatch) => {
  const { data } = await axios.get('/User/');

  return dispatch(
    setAccountData({
      me: data,
    }),
  );
};

export const fetchAccount = () => async (dispatch) => {
  const [
    { value: { data: me = initialState.me } = {} },
    { value: { data: buyer = initialState.buyer } = {} },
    { value: { data: seller = initialState.seller } = {} },
    { value: { data: { results: creditCardsInit = [] } = {} } = {} },
    { value: { data: buyerDayParting = initialState.buyerDayParting } = [] },
  ] = await Promise.allSettled([
    axios.get('/User/'),
    axios.get('/Buyer/'),
    axios.get('/Seller/'),
    axios.get('/CreditCard/'),
    axios.get('/Buyer/Dayparting/'),
    fetchPermissions()(dispatch),
    dispatch(
      buyClicksCampaignApi.endpoints.getHasBuyClicksCampaigns.initiate(),
    ),
    dispatch(buyLeadsCampaignApi.endpoints.getHasBuyLeadsCampaigns.initiate()),
  ]);

  // Needed for impersonation
  await axios.get('/csrf_cookie_refresh/').catch(() => {
    // Could return 403, but thats expected
  });

  const creditCards = creditCardsInit.reverse();

  return dispatch(
    setAccountData({
      me,
      buyer,
      seller,
      creditCards,
      buyerDayParting,
    }),
  );
};

export const updateMe = (values) => async (dispatch) => {
  const { data: me } = await axios.patch('/User/', values);

  return dispatch(
    updateMeAction({
      me,
    }),
  );
};

export const initMFA =
  ({ verificationType, ...credentials }) =>
  async () => {
    let vType = verificationType;

    if (vType === 'phone') {
      vType = 'sms';
    }

    return axios.post('/init-mfa-verification/', {
      verification_type: vType,
      ...credentials,
    });
  };

export const logInMFA = (params) => async (dispatch) => {
  await axios.post('/login-mfa/', params);

  return dispatch(fetchAccount());
};

export const logIn = (credentials) => async (dispatch) => {
  await axios.post('/login/', credentials);

  return dispatch(fetchAccount());
};

export const logOut = () => async (dispatch) => {
  localStorage.clear();
  await axios.post('/logout/');

  dispatch(resetPermissionsState());
  return dispatch(resetAccountState());
};

export const triggerBuyerCreditCardCharge = () => async () => {
  await axios.post('/TriggerBuyerCreditCardCharge/');
};

export const signUp =
  ({
    email,
    firstName,
    lastName,
    captchaToken,
    password,
    phone,
    postcode,
  } = {}) =>
  async () => {
    await axios.post('/User/', {
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      phone,
      captcha_token: captchaToken,
      postcode,
    });
  };
export const resetPassword = (credentials) => () => {
  return axios.post('/reset_password/', credentials);
};

export const createNewPassword =
  ({ uid, token, password: new_password1, password2: new_password2 }) =>
  () => {
    return axios.post('/reset_password_confirm/', {
      uid,
      token,
      new_password1,
      new_password2,
    });
  };

export const verifyUser =
  ({ verificationType }) =>
  () => {
    let vType = verificationType;

    if (vType === 'phone') {
      vType = 'sms';
    }

    return axios.post('/VerifyUser/', {
      verification_type: vType,
    });
  };

export const verifyUserConfirm =
  ({ verificationType, code }) =>
  async (dispatch) => {
    let vType = verificationType;

    if (vType === 'phone') {
      vType = 'sms';
    }

    await axios.post('/VerifyUserConfirm/', {
      verification_type: vType,
      verification_code: code,
    });

    return dispatch(fetchMe());
  };

export const createBuyer = (values, withDefaultValues) => async (dispatch) => {
  let {
    billing_method,
    billing_type,
    prepay_amount,
    is_lead_buyer,
    is_click_buyer,
    click_buyer_settings,
    lead_buyer_settings,
    // eslint-disable-next-line prefer-const
    ...rest
  } = values || {};

  if (withDefaultValues) {
    billing_method = billing_method || CREDIT_CARD;
    billing_type = billing_type || PREPAY;
    prepay_amount = prepay_amount || 50;
    is_lead_buyer = is_lead_buyer || false;
    is_click_buyer = is_click_buyer || false;
    click_buyer_settings = {
      click_cap: 0,
      click_cap_timeframe: MONTH,
      click_budget: 0,
      click_budget_timeframe: MONTH,
      ...click_buyer_settings,
    };
    lead_buyer_settings = {
      lead_cap: 0,
      lead_cap_timeframe: MONTH,
      lead_budget: 0,
      lead_budget_timeframe: MONTH,
      ...lead_buyer_settings,
    };
  }

  const { data: buyer } = await axios.post('/Buyer/', {
    billing_method,
    billing_type,
    prepay_amount,
    is_lead_buyer,
    is_click_buyer,
    click_buyer_settings,
    lead_buyer_settings,
    ...rest,
  });

  return dispatch(setAccountData({ buyer }));
};

export const updateBuyer = (values) => async (dispatch) => {
  const { data: buyer } = await axios.patch('/Buyer/', values);

  return dispatch(
    updateBuyerAction({
      buyer,
    }),
  );
};

export const createCreditCard = (values) => async (dispatch) => {
  const { data: creditCard } = await axios.post('/CreditCard/', values);

  return dispatch(
    addCreditCardAction({
      creditCard,
    }),
  );
};

export const deleteCreditCard = (id) => async (dispatch) => {
  await axios.delete(`/CreditCard/${id}/`);

  return dispatch(
    removeCreditCardAction({
      id,
    }),
  );
};

export const makePrimaryCreditCard = (id) => async (dispatch) => {
  await axios.post(`/CreditCard/${id}/make_primary/`);

  return dispatch(
    makePrimaryCreditCardAction({
      id,
    }),
  );
};

export const createBuyerDayParting = (values) => async (dispatch) => {
  const { data: buyerDayParting } = await axios.post(
    '/Buyer/Dayparting/',
    values,
  );

  return dispatch(
    addBuyerDayPartingAction({
      buyerDayParting,
    }),
  );
};

export const updateBuyerDayParting =
  ({ id, data }) =>
  async (dispatch) => {
    const { data: buyerDayParting } = await axios.put(
      `/Buyer/Dayparting/${id}/`,
      data,
    );

    return dispatch(
      updateBuyerDayPartingAction({
        buyerDayParting,
      }),
    );
  };

export const deleteBuyerDayParting =
  ({ id }) =>
  async (dispatch) => {
    await axios.delete(`/Buyer/Dayparting/${id}/`);

    return dispatch(deleteBuyerDayPartingAction({ id }));
  };

/**
 * Selectors
 */
export const selectAccount = (state) => state.account;
export const selectMe = (state) => state.account.me;
export const selectBuyer = (state) => state.account.buyer;
export const selectBuyerPaused = (state) => {
  return state.account.buyer.buyerStatus === PAUSED;
};
export const selectSeller = (state) => state.account.seller;
export const selectCreditCards = (state) => state.account.creditCards;
export const selectBuyerDayParting = (state) => state.account.buyerDayParting;
export const selectIsLoggedIn = (state) => !!state.account.me?.email;

export const selectMaskedPhone = (state) => {
  const phone = state.account.me?.phone || '';
  const phoneMask = state.appConfig?.phoneMask;

  if (!phoneMask) return phone;

  const phoneMasker = IMask.createMask({
    mask: phoneMask,
  });

  phoneMasker.resolve(phone);

  return phoneMasker.value;
};

export default accountSlice.reducer;
