/* eslint-disable no-template-curly-in-string */
/**
 * All messages could be found here:
 * https://github.com/jquense/yup/blob/master/src/locale.ts
 */

const mixed = {
  required: 'This field is required',
};

const string = {
  email: 'Please enter a valid email address',
  postcode: 'Please enter a valid zip code',
  phone: 'Please enter a ${digitsAmount}-digit number',
  url: 'Please enter a valid url',
  time: 'Invalid time',
  min: 'Minimum ${min} characters',
  maax: 'Maximum ${max} characters',
  onlyLetters: 'Only letters are allowed for this field',
  notOnlyNumbers: '${path} must contain not only digits',
  includesUppercase: '${path} must contain at least one uppercase character',
  includesLowercase: '${path} must contain at least one lowercase character',
  includesDigit: '${path} must contain at least one digit',
  includesSpecialChar: '${path} must contain at least one special character',
};

const number = {};

const date = {};

const boolean = {};

const object = {};

const array = {
  unique: 'Only unique values are allowed',
};

export default {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
};
