import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';

export const icons = [
  'nav-control',
  'polygon-left',
  'polygon-right',
  'polygon-down',
  'polygon-top',
  'close-thin',
  'menu',
  'info',
  'double-arrow-right',
  'double-arrow-left',
  'campaign',
  'close',
  'list',
  'account',
  'calendar',
  'contacts',
  'sound',
  'progress',
  'check',
  'check-circle',
  'search',
  'add',
  'delete',
  'show',
  'arrow-up',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'billing',
  'star',
  'import',
  'import-2',
  'hide',
  'export',
  'pause',
  'play',
  'duplicate',
  'log-out',
  'settings',
  'graph',
  'alert',
  'wallet',
  'union',
  'leads',
  'data',
  'edit',
  'dollar',
  'dollar-circle',
  'phone',
  'email',
  'apply',
  'marker',
  'mail-income',
  'bank',
  'transfer',
  'clock',
  'filter-active',
  'filter-inactive',
  'points',
  'cap-circle',
  'archive',
  'unarchive',
];

export const styles = () => ({
  /* Styles applied to the root element. */
  root: {
    fontSize: '1rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.MuiIcon-fontSizeSmall': {
      fontSize: '0.75rem',
    },

    '&.MuiIcon-fontSizeLarge': {
      fontSize: '1.5rem',
    },

    '&.MuiIcon-fontSizeInherit': {
      fontSize: 'inherit',
    },
  },
});

const BlIcon = forwardRef(function BlIcon(props, ref) {
  const { icon, className, classes, ...rest } = props;

  return (
    <Icon
      ref={ref}
      component="i"
      className={clsx(className, classes.root, `icon-${icon}`, 'BlIcon-root')}
      {...rest}
    />
  );
});

BlIcon.propTypes = {
  // ...Icon.propTypes,
  classes: PropTypes.object,
  className: PropTypes.string,
  icon: PropTypes.oneOf(icons),
  fontSize: PropTypes.oneOf(['default', 'inherit', 'large', 'small']),
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'action',
    'error',
    'disabled',
  ]),
};

export default withStyles(styles, { name: 'BlIcon' })(BlIcon);
